import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAlert.figmaUrl.ios} codeUrl={checklists.componentAlert.codeUrl.ios} checklists={checklists.componentAlert.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Alert use to display a list of options on a temporary surface.`}</p>
    <p>{`Use Legion alert styles to modify alert with lots of attributes that make you easier.`}</p>
    <h3>{`Usage Default LegionUI`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`Alert(
  title: "sample Title",
  subtitle: "sample Subtitle",
  action: "sample CTA copy"
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Legion Have 4 Variants of Alert :`}</p>
    <div {...{
      "style": {
        "backgroundColor": "white",
        "width": "100%",
        "display": "grid",
        "gridTemplateColumns": "25% 25% 25% 25%",
        "gap": "2rem"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Error`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/alertios-1.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        Alert( title: "Info Title", subtitle: "Interactively monetize corporate alignment", action: "Call to action" )
        .theme(variant: .error)
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Information`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/alertios-2.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        Alert( title: "Info Title", subtitle: "Interactively monetize corporate alignment", action: "Call to action" )
        .theme(variant: .information)
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Success`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/alertios-3.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        Alert( title: "Info Title", subtitle: "Interactively monetize corporate alignment", action: "Call to action" )
        .theme(variant: .success)
      `}</code>{`
    `}</pre>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "item-three"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "textAlign": "left"
          }
        }}>{`Warning`}</div>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/alertios-4.png"
          }}></img>{`
    `}</div>{`
    Default Legion UI
    `}<pre parentName="div">{`      `}<code parentName="pre">{`
        Alert( title: "Info Title", subtitle: "Interactively monetize corporate alignment", action: "Call to action" )
        .theme(variant: .warning)
      `}</code>{`
    `}</pre>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creates a title view that displays a string literal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subtitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creates a subtitle view that displays a string literal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Creates a button that displays text contains a string literal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`empty string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a title view fully-customized by SwiftUI views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EmptyView()`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subtitleView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a sutitle view fully-customized by SwiftUI views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EmptyView()`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionView`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Create a cta button view fully-customized by SwiftUI views`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EmptyView()`}</td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`Example Project`}</h2>
    <p>{`Default LegionUI`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`Alert {
    Text("Info Custom")
    .LGNBodyLarge(
        color: Color.LGNTheme.tertiary800
    )
    .padding(.bottom, 6)
} subtitle: {
    Text("Interactively monetize corporate alignment")
    .LGNCaptionLarge(
        color: Color.LGNTheme.tertiary500
    )
    .padding(.bottom, 6)
} action: {
    HStack {
        Image(systemName: "info.circle")
        Text("Latest updated at 31, Jan 2023 12:12:39")
            .LGNCaptionSmall(color: Color.LGNTheme.tertiary800)

    }
}
.theme(variant: .warning)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      